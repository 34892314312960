import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import Home from "./views/Home";
import SubmitProject from './components/SubmitProject'
import Profile from "./views/Profile";
import history from "./utils/history";
import Dashboard from './components/Dashboard';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Content from "./components/Content";
initFontAwesome();

const App = () => {

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/profile" component={Profile} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/submitProject" component={SubmitProject} />
          <Route path="/content" component={Content} />
        </Switch>
        {/* <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/submitProject" component={SubmitProject} />
            <Route path="/content" component={Content} />
          </Switch>
        </Container> */}
      </div>
    </Router>
  );
};

export default App;
