
import { Component } from "react";
import { XTMApiLayerClient } from './XTMApiLayer';
class SubmittedPortalApi extends Component {
  getToLanguageData() {
    const path = 'https://reqres.in/api/users?page=2';
    return (
      XTMApiLayerClient.GetCall(path)
        .then(result => {
          const items = [];
          for (var i = 0; i < result.data.length; i++) {
            console.log("items" + result.data[i]);
            items.push(result.data[i]);
          }

          return items;

          // console.log("lengh"+ result.data.length + arr);
          // return result.data;
        }));
  }

  getFromLanguageData() {
    const path = 'https://reqres.in/api/users?page=2';
    return (
      XTMApiLayerClient.GetCall(path)
        .then(result => {
          const items = [];
          for (var i = 0; i < result.data.length; i++) {
            console.log("items" + result.data[i]);
            items.push(result.data[i]);
          }

          return items;

          // console.log("lengh"+ result.data.length + arr);
          // return result.data;
        }));
  }

  getSubmitData() {
    console.time('Timer1: ')
    // Testing URL
    // const path = 'https://portalapiconnectapp-ax4qfg5x3a-ez.a.run.app/getTranslatedFiles?user=' + localStorage.getItem('email');
    // Production URL
    const path = 'https://portalapiconnectapp-je3mgyzyga-ez.a.run.app/getTranslatedFiles?user=' + localStorage.getItem('email');
    
    return (
      XTMApiLayerClient.GetCall(path)
        .then(result => {
          console.timeEnd('Timer1: ');
          console.log('result ', result.data)
          // const arr = JSON.stringify(result.data[0]);
          // const items = [];
          // for (var i = 0; i < result.data.length; i++) {
          //   console.log("items" + result.data[i]);
          //   items.push(result.data[i]);
          // }

          // return items;

          // console.log("lengh"+ result.data.length + arr);
          return result; 
        }));
  }

  deleteRecord(projectId) {
    // Testing URL
    // const path = 'https://portalapiconnectapp-ax4qfg5x3a-ez.a.run.app/deleteTranslation?projectId=' + projectId; 
    // Production URL 
    const path = 'https://portalapiconnectapp-je3mgyzyga-ez.a.run.app/deleteTranslation?projectId=' + projectId;

    return (
      XTMApiLayerClient.PostCall(path)
      .then(result => {
        console.timeEnd('Timer1: ');
        console.log('result ', result.data)
        return result; 
      })     
    );
  }

}
export const SubmittedApiLayerClient = new SubmittedPortalApi();
export default SubmittedPortalApi;