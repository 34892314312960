import React, { Component } from 'react';
import { auth0Client } from '../auth';

class Home extends Component {
  constructor() {
    super();
    console.log(auth0Client)
  }
  componentDidMount() {
    console.log(auth0Client)
    if (!auth0Client.isAuthenticated()) {
      auth0Client.signIn();
    }

  }
  signOut = () => {
    auth0Client.signOut();
    this.props.history.replace('/');
  }
  render() {
    return (
      <div>
        {' '}
      </div>
    )

  }

}

export default Home;
