import React, { Component } from "react";
import { render } from "react-dom";
import Content from "./Content";
import SubmitProject from "./SubmitProject";
import PropTypes from "prop-types";


class RenderPage extends Component {

    constructor(props){
        super(props);
        console.log(this.props);
        this.state = {
            data: this.props.dataParentToChild
        }
    }

    render() {
        let text;
        console.log(this.props);
        // const info = this.state.data;
        const info = this.props.dataParentToChild;

        if ((this.props.tab === '1')) {
            return text = <Content dataToChild = {info}/>
        } else if ((this.props.tab === '2')) {
            return text = <SubmitProject />
        } 
        else if ((this.props.tab === null) && (sessionStorage.getItem('activeTab') === 'Request Translation')) {
            return text = <Content dataToChild = {info}/>
        } else if((this.props.tab === null) && (sessionStorage.getItem('activeTab') === 'Submit Project')){
            return text = <SubmitProject />
        } else if(sessionStorage.getItem('activeTab') === 'Request Translation') {
            return text = <Content dataToChild = {info}/>;
        }
        // else if ((this.props.tab === null) && (localStorage.getItem('activeTab') === 'Request Translation')) {
        //     return text = <Content />
        // } else if((this.props.tab === null) && (localStorage.getItem('activeTab') === 'Submit Project')){
        //     return text = <SubmitProject />
        // } else if(localStorage.getItem('activeTab') === 'Request Translation') {
        //     return text = <Content />;
        // }
        else if(this.props.tab === null) {
            return text = <Content dataToChild = {info}/>
        }

        return (
            <div>
                {text}
                {}
            </div>

        )
    }
}

export default RenderPage;