
import { Component } from "react";
import { XTMApiLayerClient } from './XTMApiLayer';

class RequestPortalApi extends Component {
  getToLanguageData() {
    
    // Testing URL
    // const path = 'https://portalapiconnectapp-ax4qfg5x3a-ez.a.run.app/getLanguages?type=target';
    // Production URL 
    const path = 'https://portalapiconnectapp-je3mgyzyga-ez.a.run.app/getLanguages?type=target';

    return (
      XTMApiLayerClient.GetCall(path)
        .then(result => {
          console.log(result.target);
          // const arr = JSON.stringify(result.data[0]);
          // const items = [];              
          //         for(var i = 0; i < result.data.length; i++) {
          //           console.log("items"+ result.data[i]);
          //           items.push(result.data[i]);
          //         }

          //         return items;

          // console.log("lengh"+ result.data.length + arr);
          return result;
        }));
  }

  getFromLanguageData() {

    // Testing URL
    // const path = 'https://portalapiconnectapp-ax4qfg5x3a-ez.a.run.app/getLanguages?type=source';
    // Production URL 
    const path = 'https://portalapiconnectapp-je3mgyzyga-ez.a.run.app/getLanguages?type=source';    
    return (
      XTMApiLayerClient.GetCall(path)
        .then(result => {
          console.log(result)
          // const arr = JSON.stringify(result.data[0]);
          // const items = [];              
          //         for(var i = 0; i < result.data.length; i++) {
          //           console.log("items"+ result.data[i]);
          //           items.push(result.data[i]);
          //         }

          //         return items;

          // console.log("lengh"+ result.data.length + arr);
          // return result.data;
          return result;
        }));
  }

  postSubmitData(opts) {
    
    // Testing URL
    // const path = 'https://portalapiconnectapp-ax4qfg5x3a-ez.a.run.app/submitFiles';
    // Production URL 
    const path = 'https://portalapiconnectapp-je3mgyzyga-ez.a.run.app/submitFiles';        
    
    return (
      XTMApiLayerClient.PostCall(path, opts)
        .then(result => {
          // const arr = JSON.stringify(result.data[0]);
          // const items = [];
          // for (var i = 0; i < result.data.length; i++) {
          //   console.log("items" + result.data[i]);
          //   items.push(result.data[i]);
          // }

          // return items;

          // console.log("lengh"+ result.data.length + arr);
          return result;
        }));
  }

  postSubmitCommercialAndCommunicationData(opts) {
    
    // Testing URL
    // const path = 'https://portalapiconnectapp-ax4qfg5x3a-ez.a.run.app/submitCommercialAndCommuncationFile';
    // Production URL 
    const path = 'https://portalapiconnectapp-je3mgyzyga-ez.a.run.app/submitCommercialAndCommuncationFile';
    
    return (
      XTMApiLayerClient.PostCall(path, opts)
        .then(result => {

          return result;
        }));
  }

  getSubmitData() {
    
    // Testing URL
    // const path = 'https://portalapiconnectapp-ax4qfg5x3a-ez.a.run.app/submitFiles';
    // Production URL 
    const path = 'https://portalapiconnectapp-je3mgyzyga-ez.a.run.app/submitFiles';    
    
    return (
      XTMApiLayerClient.GetCall(path)
        .then(result => {
          // const arr = JSON.stringify(result.data[0]);
          // const items = [];
          // for (var i = 0; i < result.data.length; i++) {
          //   console.log("items" + result.data[i]);
          //   items.push(result.data[i]);
          // }

          // return items;

          // console.log("lengh"+ result.data.length + arr);
          return result;
        }));
  }
}
export const RequestApiLayerClient = new RequestPortalApi();
export default RequestPortalApi;