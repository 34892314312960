import React, { Component } from "react";
import { Button, Col, Card, Form, Row, Spinner } from "react-bootstrap";
import { Input } from 'reactstrap';
import switchBtn from './../assets/SwitchButton.png';
import './Content.css';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import { RequestApiLayerClient } from '../service/RequestPortalApi';
import PropTypes from "prop-types";


var arr = [];
var arr1 = [];
var changeFrom = '';
var selectOne = '';
var selectMulti = [];
var multioption = [];
var selectSource = '';
var current_date = new Date();
let date = current_date.getDate() + 1;
let month = current_date.getMonth() + 1;
let year = current_date.getFullYear();
var separator = '-';
var dueDateVal = `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;

class Content extends Component {

  constructor(props) {
    super(props);

    RequestApiLayerClient.getToLanguageData()
      .then(result => {
        arr1 = [];
        for (var data of result.data.target.split(',')) {
          arr1.push(data)
        }
        console.log(arr1);
        arr1.forEach((element) => {
          this.state.optionsTo.push({ label: element, value: element })
        })
      });

    RequestApiLayerClient.getFromLanguageData()
      .then(result => {
        console.log(result);
        arr = [];
        for (var data of result.data.source.split(',')) {
          arr.push(data)
        }
        console.log("From Lang: "+arr);
        arr.forEach((element) => {
          if (element === 'English (UK)-en_GB') {
            this.state.optionsFrom.push({ label: element, value: element })
            selectOne = element;
            this.setState({ selectedFrom : element });
          }
        })
      });
    this.state = {
      // Initially, no file is selected 
      selectedFile: [],
      dropDownValue: 'Select Value',
      defaultValue: 'Select a color',
      dropdownFrom: false,
      dropdownTo: false,
      optionsTo: [],
      optionsFrom: [],
      fileList: [],
      selected: [],
      selectedTo: [],
      selectedFrom: '',      
      optionLabel: [],
      optionValue: [],
      mimeType: [],
      successMessage: '',
      dueDate : dueDateVal,      
      sourceOptions: [
        { label: 'Machine Translate', value: 'machine_translate' },
        { label: 'Articulate for INGKA - Top quality', value: 'Articulate - Ingka' },
        { label: 'Articulate for INGKA - Medium quality', value: 'Articulate Ingka - Medium quality' },
        { label: 'Articulate for INGKA - MT only', value: 'Articulate Ingka - MT only' },
        { label: 'Articulate for Inter IKEA - Top quality', value: 'Articulate - Inter' },
        { label: 'Articulate for Inter IKEA - Medium quality', value: 'Articulate for Inter IKEA - Medium quality' },
        { label: 'Articulate for Inter IKEA - MT only', value: 'Articulate for Inter IKEA - MT only' },
        { label: 'DominKnow - Top quality', value: 'DomiKnow' },
        { label: 'DominKnow - Medium quality', value: 'DominKnow - Medium quality' },
        { label: 'DominKnow - MT only', value: 'DominKnow - MT only' } 
        
        // { label: 'Publicera', value: 'Publicera' },
        // { label: 'Other', value: 'Other' },
        // { label: 'Commercial', value: 'Commercial' },-
        // { label: 'Communication', value: 'Communication' },
        // { label: 'People & Culture', value: 'people_and_culture' }, articulate_inter_ikea
        // { label: 'Articulate', value: 'Articulate' }, articulate_ingka
        
      
        
       
       
      ],
      source: '',
      fetchingResponse: false,
      errorMessage: '',
      layout: this.props.dataToChild,
      emailError: 0,
      isChecked: false,
    };
  }

  // Below function is used to set (Parent Component) updated state value in child Component
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps.dataToChild)
    this.setState({ layout: nextProps.dataToChild });
    this.setState({ source: '' });
  }

  validateEmail = (e) => {
    var email = e.target.value
    console.log(email);
    let pattern = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    let result = pattern.test(email);  
    console.log(result);
    if (result == false) {
        this.setState({ emailError: 0})
    } else {
      this.setState({ emailError: 1})
    }    
  }  

  onFileChange = (event) => {
    var temp = [];
    var selectFiles = []
    var strSplit = [];

    var domiknowList = [
      'zip'
    ];
    var publiceraList = ['xliff', 'xlf'];
    var articulateList = ['zip'];
    var otherList = ['xml', 'srt', 'xliff', 'xlf'];
    var commList = [
      'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'xlsm',
      'sxw', 'odt', 'idml', 'indb', 'indd', 'odp', 'ods', 'mif', 'xliff', 'xlf','json'
    ];
    var cultureList = ['doc', 'docx', 'xls'];
    var machinetranslateList = [
      'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'xlsm',
      'sxw', 'odt', 'idml', 'indb', 'indd', 'odp', 'ods', 'mif', 'xliff', 'xlf','zip','pdf','json'
    ];
    var machinetranslateListadvanced = ['zip'];
    
    // Update the state 
    for (var data of event.target.files) {
      temp.push(data.name);
      selectFiles.push(data)
    }
    console.log(temp);
    this.setState({ selectedFile: selectFiles })
    for (var fileExt of temp) {
       strSplit.push(fileExt.split('.').pop());
    }
    console.log(strSplit)

    if (selectSource === 'DomiKnow'|| selectSource === 'DominKnow - MT only'|| selectSource === 'DominKnow - Medium quality') {

      // if (strSplit.includes('xml') || strSplit.includes('srt')) {
      if (strSplit.every(i => domiknowList.includes(i))) {
        this.setState({ fileList: temp })
        console.log(this.state.selectedFile)
      } else {
        alert('Upload correct extension file' + '\n' + 'Files unsupported ')
        var inputFile = document.getElementById('exampleFile');
        inputFile.value = '';
        this.setState({ fileList: [] })
      }
    }
    if (selectSource === '') {

      // if (strSplit.includes('xml') || strSplit.includes('srt')) {
      if (strSplit.every(i => machinetranslateList.includes(i))) {
        this.setState({ fileList: temp })
        console.log(this.state.selectedFile)
      } else {
        alert('Upload correct extension file' + '\n' + 'Files unsupported ')
        var inputFile = document.getElementById('exampleFile');
        inputFile.value = '';
        this.setState({ fileList: [] })
      }
    }

    if (selectSource === 'Other' ) {
      if (strSplit.every(i => otherList.includes(i))) {
        this.setState({ fileList: temp })
        console.log(this.state.selectedFile)
      } else {
        alert('Upload correct extension file' + '\n' + 'Files unsupported ')
        var inputFile = document.getElementById('exampleFile');
        inputFile.value = '';
        this.setState({ fileList: [] })
      }
    }

    if (selectSource === 'Publicera') {
      if (strSplit.every(i => publiceraList.includes(i))) {
        this.setState({ fileList: temp })
        console.log(this.state.selectedFile)
      } else {
        alert('Upload correct extension file' + '\n' + 'Files unsupported ')
        var inputFile = document.getElementById('exampleFile');
        inputFile.value = '';
        this.setState({ fileList: [] })
      }
    }

    if (selectSource === 'Articulate' || selectSource === 'Articulate - Ingka' || selectSource === 'Articulate - Inter' || selectSource === 'Articulate Ingka - MT only' || selectSource === 'Articulate Ingka - Medium quality' || selectSource === 'Articulate for Inter IKEA - MT only' || selectSource === 'Articulate for Inter IKEA - Medium quality') {
      if (strSplit.every(i => articulateList.includes(i))) {
        this.setState({ fileList: temp })
        console.log(this.state.selectedFile)
      } else {
        alert('Upload correct extension file' + '\n' + 'Files unsupported ')
        var inputFile = document.getElementById('exampleFile');
        inputFile.value = '';
        this.setState({ fileList: [] })
      }
    }    
    if (selectSource === 'machine_translate' ) {
      if (strSplit.every(i => machinetranslateListadvanced.includes(i))) {
        this.setState({ fileList: temp })
        console.log(this.state.selectedFile)
      } else {
        alert('Upload correct extension file' + '\n' + 'Files unsupported ')
        var inputFile = document.getElementById('exampleFile');
        inputFile.value = '';
        this.setState({ fileList: [] })
      }
    }    

    if (selectSource === 'Commercial' || selectSource === 'Communication') {
      if (strSplit.every(i => commList.includes(i))) {
        this.setState({ fileList: temp })
        console.log(this.state.selectedFile)
      } else {
        alert('Upload correct extension file' + '\n' + 'Files unsupported ')
          var inputFile = document.getElementById('exampleFile');
        inputFile.value = '';
        this.setState({ fileList: [] })
      }
    }

    if (selectSource === 'people_and_culture') {
      if (strSplit.every(i => cultureList.includes(i))) {
        this.setState({ fileList: temp })
        console.log(this.state.selectedFile)
      } else {
        alert('Upload correct extension file' + '\n' + 'Files unsupported ')
        var inputFile = document.getElementById('exampleFile');
        inputFile.value = '';
        this.setState({ fileList: [] })
      } 
    }    

  };

  // On file upload (click the upload button) 
  onFileUpload = (event) => {
    console.log('event --- ', event.target);
    console.log('files : ', this.state.fileList);

    if(this.state.layout == 'advanced'){
      var dueDateValue ;
      if(selectSource=='machine_translate')
      {
        if (selectSource === 'machine_translate')
        {
          
          const today = new Date();

          today.setDate(today.getDate() + 1);
           dueDateValue = today.toISOString().split('T')[0]
          // var today = new Date();
          //     var dd = String(today.getDate()+1).padStart(2, '0');
          //     var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          //     var yyyy = today.getFullYear();
          //     dueDateValue = yyyy + '-' + mm + '-' + dd;
            
          
       
        }
      }
      else
       dueDateValue = document.getElementById('due_date').value;
      console.log(dueDateValue);
      var getProjectName = document.getElementsByClassName('projName'); 
      var proj_name = '';
      if (getProjectName.length > 0) {
        proj_name = getProjectName[0].value;
      }      
    }

    if(this.state.layout == 'basic'){

      if ((this.state.fileList.length > 0) && (this.state.emailError == 1)) {
        var fileName;
        var fileSize;
        // Create an object of formData 
        console.log(this.state.selectedFile)
        for (fileName of this.state.selectedFile) {
          console.log(fileName)
          var size = (fileName.size) / Math.pow(1024, 3);
          if (size > 2) {
            fileSize = true;
            break;
          }
        }


        if (fileSize) {
          alert('File size exceeded 32mb, Please select file less than 32mb.')
        } else {
          console.log('You can proceed further');
          var email_id = document.getElementById('userEmail').value;
          const formData = new FormData();
          this.setState({ fetchingResponse: true })
          if (this.state.fetchingResponse === true) {
            bold = document.getElementById('message');
            bold.style.color = '#ffe135'
          }
          if (this.state.fetchingResponse === false) {

          }

          this.state.selectedFile.forEach(fileName => {
            console.log(fileName)
            formData.append('files', fileName, fileName.name)
          })
          formData.append('sourceLanguage', selectOne);
          formData.append('targetLanguage', selectMulti);          
          formData.append('source', 'Commercial');
          formData.append('user', localStorage.getItem('email'));
          formData.append('emailAddress', email_id);
          formData.append('initiator', 'Portal');
          console.log(formData);

          RequestApiLayerClient.postSubmitCommercialAndCommunicationData(formData).then(res => {
            console.log(res);
            console.log('status : ', res.status)
            if ((res.status >= 200) && (res.status < 300)) {
              this.setState({
                fetchingResponse: false,
                successMessage: res.status,
                errorMessage: ''
              })
              if (this.state.successMessage) {
                bold = document.getElementById('message');
                bold.style.color = 'green'
              }
            }
          }).catch(error => {
            console.log(error);
            this.setState({
              errorMessage: 'error',
              fetchingResponse: false,
              successMessage: 'error encountered'
            });
          })          
        }
      } else {
        console.log(this.state.emailError);
        alert('Please fill all field with valid input!')
      }

    } else {

      if (selectSource === '' || selectSource === 'Publicera' || selectSource === 'Other') {

        if ((this.state.fileList.length > 0) && (selectMulti.length > 0) && (selectOne !== '') && (selectSource !== '') && (proj_name !== '') && (this.state.emailError == 1)) {
          var lang;
          var bold;
          var langCheck;
          var fileName;
          var fileSize;
          // Create an object of formData 
          console.log(this.state.selectedFile)
          for (fileName of this.state.selectedFile) {
            console.log(fileName)
            var size = (fileName.size) / Math.pow(1024, 3);
            if (size > 2) {
              fileSize = true;
              break;
            }
          }
          for (lang of selectMulti) {
            if (lang === selectOne) {
              langCheck = true;
              break;
            }
          }
  
          if (fileSize && langCheck) {
            alert('Please select file size less than 32mb' +
              '\n' +
              'Source and Traget language should not be same, Please select different languages to proceed');
          } else if (langCheck) {
            alert('Source and Target language should not be same. Please select different languages to proceed further.')
          } else if (fileSize) {
            alert('File size exceeded 32mb, Please select file less than 32mb.')
          } else {
            console.log('You can proceed further');
            const formData = new FormData();
            this.setState({ fetchingResponse: true })
            if (this.state.fetchingResponse === true) {
              bold = document.getElementById('message');
              bold.style.color = '#ffe135'
            }
            if (this.state.fetchingResponse === false) {
  
            }
            // Update the formData object 
  
            this.state.selectedFile.forEach(fileName => {
              console.log(fileName)
              formData.append('files', fileName, fileName.name)
            })
            var email_id = document.getElementById('userEmail').value;
            // Details of the uploaded file 
            formData.append('sourceLanguage', selectOne);
            formData.append('targetLanguage', selectMulti);
            formData.append('projectName', proj_name);
            formData.append('dueDate', dueDateValue);
            formData.append('source', this.state.source);
            formData.append('user', localStorage.getItem('email'));
            formData.append('emailAddress', email_id);
            formData.append('initiator', 'Portal')
            console.log(formData);
  
            RequestApiLayerClient.postSubmitData(formData).then(res => {
              console.log(res);
  
              if ((res.status >= 200) && (res.status < 300)) {
                this.setState({
                  fetchingResponse: false,
                  successMessage: res.status,
                  errorMessage: ''
                })
                if (this.state.successMessage) {
                  bold = document.getElementById('message');
                  bold.style.color = 'green'
                }
              }
            }).catch(error => {
              console.log(error);
              this.setState({
                errorMessage: 'error',
                fetchingResponse: false,
                successMessage: 'error encountered'
              });
            })
          }
  
        } else {
          console.log('select file first');
          alert('Please fill all field with valid input!')
        }
      } else if (selectSource === 'Commercial' || selectSource === 'Communication' || selectSource === 'people_and_culture'|| selectSource === 'machine_translate') {
        if ((this.state.fileList.length > 0) && (selectMulti.length > 0) && (selectOne !== '') && (selectSource !== '') && (proj_name !== '') && (this.state.emailError == 1)) {
          var lang;
          var bold;
          var langCheck;
          var fileName;
          var fileSize;
          console.log(this.state.selectedFile)
          for (fileName of this.state.selectedFile) {
            console.log(fileName)
            var size = (fileName.size) / Math.pow(1024, 3);
            if (size > 2) {
              fileSize = true;
              break;
            }
          }
          for (lang of selectMulti) {
            if (lang === selectOne) {
              langCheck = true;
              break;
            }
          }
  
          if (fileSize && langCheck) {
            alert('Please select file size less than 32mb ' +
              '\n' +
              'Source and Traget language should not be same, Please select different languages to proceed');
          } else if (langCheck) {
            alert('Source and Target language should not be same. Please select different languages to proceed further.')
          } else if (fileSize) {
            alert('File size exceeded 32mb, Please select file less than 32mb.')
          } else {
            console.log('You can proceed further');
            const formData = new FormData();
            this.setState({ fetchingResponse: true })
            if (this.state.fetchingResponse === true) {
              bold = document.getElementById('message');
              bold.style.color = '#ffe135'
            }
            if (this.state.fetchingResponse === false) {
  
            }
            // Update the formData object 
  
            this.state.selectedFile.forEach(fileName => {
              console.log(fileName)
              formData.append('files', fileName, fileName.name)
  
            })
  
            // Details of the uploaded file 
            var email_id = document.getElementById('userEmail').value;
            formData.append('sourceLanguage', selectOne);
            formData.append('targetLanguage', selectMulti);
            formData.append('projectName', proj_name);
            if (selectSource === 'machine_translate')
            {
            //   var today = new Date();
            //   var dd = String(today.getDate()+1).padStart(2, '0');
            //   var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            //   var yyyy = today.getFullYear();
            //  today = yyyy + '-' + mm + '-' + dd;

             const today = new Date();

             today.setDate(today.getDate() + 1);
              dueDateValue = today.toISOString().split('T')[0]
            formData.append('dueDate', dueDateValue);
            }
            formData.append('source', this.state.source);
            formData.append('user', localStorage.getItem('email'));
            formData.append('emailAddress', email_id);
            formData.append('initiator', 'Portal')
            console.log(formData);
            console.log(formData.getAll('files'))
  
            RequestApiLayerClient.postSubmitCommercialAndCommunicationData(formData).then(res => {
              console.log(res);
              console.log('status : ', res.status)
              if ((res.status >= 200) && (res.status < 300)) {
                this.setState({
                  fetchingResponse: false,
                  successMessage: res.status,
                  errorMessage: ''
                })
                if (this.state.successMessage) {
                  bold = document.getElementById('message');
                  bold.style.color = 'green'
                }
              }
            }).catch(error => {
              console.log(error);
              this.setState({
                errorMessage: 'error',
                fetchingResponse: false,
                successMessage: 'error encountered'
              });
            })
          }
  
        } else {
          console.log('select file first');
          alert('Please fill all field with valid input!')
        }
      } else if (selectSource === 'DomiKnow' || selectSource === 'DominKnow - MT only'|| selectSource === 'DominKnow - Medium quality'|| selectSource === 'Articulate'  || selectSource === 'Articulate - Ingka' || selectSource === 'Articulate - Inter' || selectSource === 'Articulate Ingka - MT only' || selectSource === 'Articulate Ingka - Medium quality' || selectSource === 'Articulate for Inter IKEA - MT only' || selectSource === 'Articulate for Inter IKEA - Medium quality' ) {
  
        if ((this.state.fileList.length > 0) && (selectSource !== '') && (proj_name !== '' && (this.state.emailError == 1))) {
          var lang;
          var bold;
          var langCheck;
          var fileName;
          var fileSize;
          // Create an object of formData 
          for (fileName of this.state.selectedFile) {
  
            var size = (fileName.size) / Math.pow(1024, 3);
            if (size > 2) {
              fileSize = true;
              break;
            }
          }
          
          for (lang of selectMulti) {
            if (lang === selectOne) {
              langCheck = true;
              break;
            }
          }
  
          if (fileSize && langCheck) {
            alert('Please select file size less than 32mb' +
              '\n' +
              'Source and Traget language should not be same, Please select different languages to proceed');
          } else if (langCheck) {
            alert('Source and Target language should not be same. Please select different languages to proceed further.')
          } else if (fileSize) {
            alert('File size exceeded 32mb, Please select file less than 32mb.')
          } else {
            console.log('You can proceed further');
            const formData = new FormData();
            this.setState({ fetchingResponse: true })
            if (this.state.fetchingResponse === true) {
              bold = document.getElementById('message');
              bold.style.color = '#ffe135'
            }
            if (this.state.fetchingResponse === false) {
  
            }
            // Update the formData object 
  
            this.state.selectedFile.forEach(fileName => {
              console.log(fileName)
              formData.append('files', fileName, fileName.name)
            })
            var email_id = document.getElementById('userEmail').value;
            
            formData.append('sourceLanguage', selectOne);
            formData.append('targetLanguage', selectMulti);
            // Details of the uploaded file 
            formData.append('projectName', proj_name);
            formData.append('dueDate', dueDateValue);
            formData.append('source', this.state.source);
            formData.append('user', localStorage.getItem('email'));
            formData.append('emailAddress', email_id);
            formData.append('initiator', 'Portal')
            console.log(formData);
  
            RequestApiLayerClient.postSubmitData(formData).then(res => {
  
              if ((res.status >= 200) && (res.status < 300)) {
                this.setState({
                  fetchingResponse: false,
                  successMessage: res.status,
                  errorMessage: ''
                })
                if (this.state.successMessage) {
                  bold = document.getElementById('message');
                  bold.style.color = 'green'
                }
              }
            }).catch(error => {
              console.log(error);
              this.setState({
                errorMessage: 'error',
                fetchingResponse: false,
                successMessage: 'error encountered'
              });
            })
          }
  
        } else {
          alert('Please fill all field with valid input!')
        }
      }
    }
  };


  selectComponent = (e) => {
    selectMulti = [];
    this.setState({ selected: [...e] });
    for (var lang of e) {
      selectMulti.push(lang.label)
    }
  }

  changedOption = (ent) => {
    selectOne = '';
    selectOne = ent.label;
    this.setState({ selectedFrom : ent });
  }

  changedDominoOption = (evt) => {
    console.log(evt.label);
    selectMulti = [];
    selectMulti.push(evt.label)
  }

  changedSourceOption = (evt) => {
    this.setState({ fileList: [] })
    selectSource = '';
    selectSource = evt.value;
    this.setState({ source: evt.value })
    var inputFile = document.getElementById('exampleFile');
    inputFile.value = '';
  }

  _handleChange = (value) => {
    this.setState({
      currentValues: this.state.currentValues.push(value)
    })
  }

  toggle = () => {
    // console.log('event captured ', evnt.target)
    this.setState({ dropdownFrom: !this.state.dropdownFrom })
    console.log(!this.state.dropdownFrom)
  }

  exchangeOptions = (event) => {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
    changeFrom = selectMulti[0];
    var fromDropDown = selectOne;
    selectOne = ''; selectMulti = [];
    selectOne = changeFrom;
    selectMulti.push(fromDropDown);
    this.state.selectedFrom = '';
    this.state.selectedTo = [];
    
    var setFromVal = { label: changeFrom, value: changeFrom };
    this.state.selectedTo.push({ label: selectOne, value: selectOne });
    this.setState({
      optionsTo: this.state.optionsFrom,
      optionsFrom: this.state.optionsTo,
      selectedFrom: setFromVal,
    });

  }

  render() {
    
    return (
      <Card>
        <Card.Body>
          <div>
            {this.state.layout === 'advanced' && (
              <Row id="nextRow">
                <Col >
                  <label> <strong>*Source/Domain Selection:</strong></label>
                  <br />
                  <Select
                    closeMenuOnSelect={true}
                    onChange={this.changedSourceOption}
                    options={this.state.sourceOptions}
                  />
                </Col>
                <Col>
                  <label> <strong>*Due date </strong></label>
                  {(this.state.source === 'machine_translate' ) ? (
                    <Select
               
                      isDisabled={true}
                    />
                  ) : (                      
                    <Input type="date" id="due_date" className="due_date" min={this.state.dueDate} />
                  )}
                  
                </Col>              
                <Col>
                      <label><strong> *Project Name </strong></label>
                      <Input type="text"  className="projName" />
                </Col>              
              </Row>
            )}              

            <br /> <br />
            {/* <h3>
              Upload File you want to translate
            </h3> */}
            <div>

            <Form>
            <Row>
              <Col>
                <label> <strong> Upload File you want to translate </strong></label>

                {(this.state.source === 'DomiKnow' || selectSource === 'DominKnow - MT only'|| selectSource === 'DominKnow - Medium quality' || this.state.source === 'Articulate' || this.state.source === 'machine_translate' || this.state.source === 'Articulate - Ingka' || this.state.source === 'Articulate - Inter'||selectSource === 'Articulate Ingka - MT only' ||selectSource === 'Articulate Ingka - Medium quality' || selectSource === 'Articulate for Inter IKEA - MT only' || selectSource === 'Articulate for Inter IKEA - Medium quality') && (
                  <Input type="file" key="uniqueId" accept='.zip' name="files" id="exampleFile" onChange={this.onFileChange} />
                )}
                {(this.state.source === 'Other') && (
                  <Input multiple key="uniqueId2" type="file" accept='.xml,.xliff,.srt,.xlf' name="files" id="exampleFile" onChange={this.onFileChange} />
                )}
                {this.state.source === 'Publicera' && (
                  <Input multiple key="uniqueId3" type="file" accept='.xliff,.xlf' name="files" id="exampleFile" onChange={this.onFileChange} />
                )}
                {(this.state.source === 'Commercial' || this.state.source === 'Communication') && (
                  <Input multiple key="uniqueId4" type="file" accept='.xliff,.xlf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.xlsm,.sxw,.odt,.ods,.odp,.mif,.idml,.indd,.indb' name="files" id="exampleFile"
                    onChange={this.onFileChange} />
                )}
                {(this.state.source === 'people_and_culture') && (
                  <Input multiple key="uniqueId5" type="file" accept='.doc,.docx,.xls,.ppt' name="files" id="exampleFile"
                    onChange={this.onFileChange} />
                )}                  
                {this.state.source === '' && (
                  <Input multiple key="uniqueId6" type="file" name="files" id="exampleFile" onChange={this.onFileChange} />
                )}                       
                {'* File size limit should not exceed 32mb'}
                {this.state.fileList !== [] && (
                  <div>
                    {this.state.fileList.map((elm) => (
                      <li key={elm}>{elm}</li>
                    ))}
                  </div>
                )}
                {this.state.fileList === [] && (
                  <div>
                    {' '}
                  </div>
                )}
              </Col>
              <Col>
                <label><strong> *Email address </strong></label>
                <Input type="email" id="userEmail" name="email" onChange={(e) => this.validateEmail(e)}/>
                {this.state.layout === 'basic'&&
                <label>
               <input type="checkbox"  checked={this.state.isChecked} onChange={(e) => this.exchangeOptions(e)}/>
               Reverse Translation <br/> Select the checkbox for 
               translation from local language to English <br/> You need to select source text langauge in 'From' Field"
                  </label>
                }
              </Col>
            </Row>                
            </Form>

            </div>
            <br /> <br />
            <hr />
            <div>
              <Row style={{ width: '100%' }}>
                <Col>
                  <label> From:</label>
                  <br />
                  {(this.state.source === 'DomiKnow' || selectSource === 'DominKnow - MT only'|| selectSource === 'DominKnow - Medium quality' || this.state.source === 'Articulate'  || this.state.source === 'Articulate - Ingka' || this.state.source === 'Articulate - Inter'||this.state.source === 'Articulate Ingka - MT only' || this.state.source === 'Articulate Ingka - Medium quality' || this.state.source === 'Articulate for Inter IKEA - MT only' || this.state.source === 'Articulate for Inter IKEA - Medium quality') ? (
                    <Select
                      closeMenuOnSelect={true}
                      onChange={this.changedOption}
                      options={this.state.optionsFrom}
                      isDisabled={true}
                    />
                  ) : (
                    // <select id="fromList" className="form-control">
                    //   <option value="en"> English </option>     
                    // </select>
                    <Select id="selectedVal"
                      closeMenuOnSelect={true}
                      onChange={this.changedOption}
                      options={this.state.optionsFrom}
                      value={this.state.selectedFrom}
                    />                    
                  )}

                </Col>
                {this.state.layout === 'advanced' && (
               <Col  md="2" lg="2" xs="2" className="mt-4">
               <div id="translationFormSwitch" style={{ width: '32px', margin: 'auto', cursor: 'pointer'}}>
                 <img src={switchBtn} id="switchBtn" width="30" onClick={(e) => this.exchangeOptions(e)}/>
               </div>                
             </Col>
            )}              

                
                <Col>
                  <label> To:</label>
                  <br />
                  {(this.state.source === 'DomiKnow' || selectSource === 'DominKnow - MT only'|| selectSource === 'DominKnow - Medium quality' || this.state.source === 'Articulate'  || this.state.source === 'Articulate - Ingka' || this.state.source === 'Articulate - Inter'|| this.state.source === 'Articulate Ingka - MT only' || this.state.source === 'Articulate Ingka - Medium quality'|| this.state.source === 'Articulate for Inter IKEA - MT only' || this.state.source === 'Articulate for Inter IKEA - Medium quality') ? (
                    <Select
                      closeMenuOnSelect={true}
                      onChange={this.changedDominoOption}
                      options={this.state.optionsTo}
                      isDisabled={true}
                    />
                  ) : (                      
                      <MultiSelect options={this.state.optionsTo} value={this.state.selected} onChange={(e) => this.selectComponent(e)} />
                  )}
                </Col>
              </Row>
              
              <br />
              <strong className="disclaimer"> 
                Always proof-read your translations and consult a professional translation service provider or the Localization 
                Specialist in your country if you intend to publish or distribute the translated material externally, or use the 
                translated material in a formal context internally. This portal is intended to help on small projects; should the 
                content for translation be over a 1,000 words, MTPE (post editing) service should be requested to Ingka global approved agencies.               
              </strong><br /> 

              <Button className="mt-2" variant="primary" type="submit" onClick={(e) => this.onFileUpload(e)}>Submit</Button>

              {this.state.fetchingResponse === true && (
                <b id="message">
                  {' '}
                  <Spinner animation="border" as="span" size="sm" />
                  <span>{' Upload is in progress .... '}</span>
                </b>
              )}
              {this.state.fetchingResponse === false && (this.state.successMessage >= 200 && this.state.successMessage < 300) && (
                <b id="message">
                  {'  Files Uploaded Successfully , Find your translated document under "Submitted Project" tab.'}
                </b>
              )}
              {this.state.fetchingResponse === false && this.state.errorMessage === 'error' && (
                <b id="message" style={{ color: 'red'}}>
                  {'  Error in Uploading Files' }
                </b>
              )}


            </div>
            <br />
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default Content;
