import React from 'react';
import { Component } from 'react';
import { SubmittedApiLayerClient } from '../service/SubmittedProjectApi';
import { Spinner, Card } from 'react-bootstrap';
import './SubmitProject.css';
import JSZip from 'jszip';
import { saveAs } from 'file-saver'
//Datatable Modules
import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
// import ReactPagination from "react-js-pagination";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { faDownload, faTrash, faFileArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

var arrData = [];
// var fileNameList = [];
// var responseText = '';
var currentPage = 0;
var searchText = '';
var filteredItems;
var arrCheck = [];
var fileName = [];
// var checked = '';

class SubmitProject extends Component {

    constructor() {
        super();
        this.state = {
            responseData: [],
            isLoading: true,
            order: 'asc',
            itemsCountPerPage: 4,
            selectedData: [],
            indexOfLastPost: '',
            indexOfFirstPost: '',
            filteringItems: false,
            pagesCount: '',
            // homePage: true
        }

        console.time('Timer: ')
        this.loadData();
    }
    componentDidMount() {
        //Get all users details in bootstrap table
          
     //initialize datatable
     
         setTimeout(function(){
            $('#example').DataTable({
                "aaSorting": [],
                columnDefs: [{
                orderable: false,
                targets: 2
                }]
              });
                $('.dataTables_length').addClass('bs-select');
          } ,1000);
     
  }

    sortRecord = (e) => {
        console.log(e.target.text);
    }

    sortSubmittedProject = () => {
        console.log(this.state.order);
        // this.setState({ responseData: arrData})
        let comparison = 1;
        if (this.state.order === 'asc') {
            this.setState({ order: 'desc' })
            arrData.sort((a, b) => {
                if (a.XTMProjectName < b.XTMProjectName) {
                    this.setState({ responseData: arrData })

                    return (comparison * -1);
                }                else if (a.XTMProjectName > b.XTMProjectName) {
                    this.setState({ responseData: arrData })

                    return (comparison * 1);
                    // console.log(b.XTMProjectName)
                } else {

                    return (comparison * 0);
                }
            });
            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
        }

        if (this.state.order === 'desc') {
            // order = 'asc'
            this.setState({ order: 'asc' });
            comparison = -1;
            arrData.sort((a, b) => {
                if (a.XTMProjectName < b.XTMProjectName) {
                    this.setState({ responseData: arrData })

                    return (comparison * -1);
                }
                else if (a.XTMProjectName > b.XTMProjectName) {
                    this.setState({ responseData: arrData })

                    return (comparison * 1);
                } else {

                    return (comparison * 0);
                }
            });
            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
        }
    }

    sortProjectName = () => {
        console.log(this.state.order);
        // this.setState({ responseData: arrData})
        let comparison = 1;
        if (this.state.order === 'asc') {
            this.setState({ order: 'desc' })
            arrData.sort((a, b) => {
                if (a.projectName < b.projectName) {
                    this.setState({ responseData: arrData })

                    return (comparison * -1);
                }
                else if (a.projectName > b.projectName) {
                    this.setState({ responseData: arrData })

                    return (comparison * 1);
                    // console.log(b.XTMProjectName)
                } else {

                    return (comparison * 0);
                }
            });
            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
        }

        if (this.state.order === 'desc') {
            // order = 'asc'
            this.setState({ order: 'asc' });
            comparison = -1;
            arrData.sort((a, b) => {
                if (a.projectName < b.projectName) {
                    this.setState({ responseData: arrData })

                    return (comparison * -1);
                }
                else if (a.projectName > b.projectName) {
                    this.setState({ responseData: arrData })

                    return (comparison * 1);
                } else {

                    return (comparison * 0);
                }
            });
            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
        }
    }

    sortDueDateSubmitted = () => {
        // this.setState({ order: 'asc' })
        console.log(this.state.order);
        let comparison = 1;
        if (this.state.order === 'asc') {
            // order = 'desc'
            this.setState({ order: 'desc' });
            arrData.sort((a, b) => {
                if (a.dueDate < b.dueDate) {
                    this.setState({ responseData: arrData })
                    return (comparison * -1);
                }
                else if (a.dueDate > b.dueDate) {
                    this.setState({ responseData: arrData })
                    return (comparison * 1);
                    // console.log(b.XTMProjectName)
                } else {
                    return (comparison * 0);
                }
            });

            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })

        }
        if (this.state.order === 'desc') {
            // order = 'asc'
            this.setState({ order: 'asc' });
            comparison = -1;
            arrData.sort((a, b) => {
                if (a.dueDate < b.dueDate) {
                    this.setState({ responseData: arrData })
                    return (comparison * -1);
                }
                else if (a.dueDate > b.dueDate) {
                    this.setState({ responseData: arrData })
                    return (comparison * 1);
                    // console.log(b.XTMProjectName)
                } else {
                    return (comparison * 0);
                }
            });

            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
        }

    }

    sortDateSubmitted = () => {
        // this.setState({ order: 'asc' })
        console.log(this.state.order);
        let comparison = 1;
        if (this.state.order === 'asc') {
            // order = 'desc'
            this.setState({ order: 'desc' });
            arrData.sort((a, b) => {
                if (a.dateSubmitted < b.dateSubmitted) {
                    this.setState({ responseData: arrData })
                    return (comparison * -1);
                }
                else if (a.dateSubmitted > b.dateSubmitted) {
                    this.setState({ responseData: arrData })
                    return (comparison * 1);
                    // console.log(b.XTMProjectName)
                } else {
                    return (comparison * 0);
                }
            });

            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })

        }
        if (this.state.order === 'desc') {
            // order = 'asc'
            this.setState({ order: 'asc' });
            comparison = -1;
            arrData.sort((a, b) => {
                if (a.dateSubmitted < b.dateSubmitted) {
                    this.setState({ responseData: arrData })
                    return (comparison * -1);
                }
                else if (a.dateSubmitted > b.dateSubmitted) {
                    this.setState({ responseData: arrData })
                    return (comparison * 1);
                    // console.log(b.XTMProjectName)
                } else {
                    return (comparison * 0);
                }
            });

            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
        }

    }

    sortStatus = () => {
        console.log(this.state.order);
        // this.setState({ order: 'asc' })
        let comparison = 1;
        if (this.state.order === 'asc') {
            // order = 'desc'
            this.setState({ order: 'desc' });
            arrData.sort((a, b) => {
                if (a.status < b.status) {
                    this.setState({ responseData: arrData })
                    return (comparison * -1);
                }
                else if (a.status > b.status) {
                    this.setState({ responseData: arrData })
                    return (comparison * 1);
                    // console.log(b.XTMProjectName)
                } else {
                    return (comparison * 0);
                }
            });
            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
            console.log(this.state.responseData)
        }

        if (this.state.order === 'desc') {
            // order = 'asc'
            this.setState({ order: 'asc' });
            comparison = -1;
            arrData.sort((a, b) => {
                if (a.status < b.status) {
                    this.setState({ responseData: arrData })
                    return (comparison * -1);
                }
                else if (a.status > b.status) {
                    this.setState({ responseData: arrData })
                    return (comparison * 1);
                    // console.log(b.XTMProjectName)
                } else {
                    return (comparison * 0);
                }
            });
            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
        }
    }

    sortSourceLanguage = () => {
        console.log(this.state.order);
        // this.setState({ order: 'asc' })
        let comparison = 1;
        if (this.state.order === 'asc') {
            // order = 'desc'
            this.setState({ order: 'desc' });
            arrData.sort((a, b) => {
                if (a.fromLanguage < b.fromLanguage) {
                    this.setState({ responseData: arrData })
                    return (comparison * -1);
                }
                else if (a.fromLanguage > b.fromLanguage) {
                    this.setState({ responseData: arrData })
                    return (comparison * 1);
                    // console.log(b.XTMProjectName)
                } else {
                    return (comparison * 0);
                }
            });
            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
           
            
       }

        if (this.state.order === 'desc') {
            // order = 'asc'
            this.setState({ order: 'asc' });
            comparison = -1;
            arrData.sort((a, b) => {
                if (a.fromLanguage < b.fromLanguage) {
                    this.setState({ responseData: arrData })
                    return (comparison * -1);
                }
                else if (a.fromLanguage > b.fromLanguage) {
                    this.setState({ responseData: arrData })
                    return (comparison * 1);
                    // console.log(b.XTMProjectName)
                } else {
                    return (comparison * 0);
                }
            });
            this.setState({
                pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            })
            const check = arrData
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
           
         
       
        }
    }


    checkDownload(event) {
        console.log(event)
    }

    handleClick(e, index) {
        e.preventDefault();
        currentPage = index
        console.log(currentPage, index);

        const check = arrData
            .slice(
                currentPage * this.state.itemsCountPerPage,
                (currentPage + 1) * this.state.itemsCountPerPage
            )
        console.log(check);
        this.setState({ responseData: check })

        if (this.state.filteringItems === true && searchText !== '') {
            this.setState({ responseData: [] })
            const filterItem = filteredItems
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )

            this.setState({ responseData: filterItem })
        }
    }

    handleSearchInput = (event) => {
        this.setState({ filteringItems: true })
        searchText = event.target.value;
        console.log(searchText);
        filteredItems = arrData.filter(function (item) {
            return item.XTMProjectName.toLowerCase().includes(searchText.toLowerCase()) ||
                item.projectName.toLowerCase().includes(searchText.toLowerCase()) ||
                item.dateSubmitted.toLowerCase().includes(searchText.toLowerCase()) ||
                item.status.toLowerCase().includes(searchText.toLowerCase()) ||
                item.fromLanguage.toLowerCase().includes(searchText.toLowerCase());

        });
        console.log(filteredItems)
        this.setState({ responseData: filteredItems })

        if (searchText === '' && (this.state.filteringItems === true || this.state.filteringItems === false)) {
            this.setState({ responseData: [] })
            this.setState({
                pagesCount: ''
            })
            this.setState({ filteringItems: false });
            const check = filteredItems
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )
            this.setState({ responseData: check })
            this.setState({
                pagesCount: Math.ceil(filteredItems.length / this.state.itemsCountPerPage)
            })
            console.log(this.state.filteringItems)

        }

        if (this.state.filteringItems === true && searchText !== '') {
            this.setState({ responseData: [] })

            const filterItem = filteredItems
                .slice(
                    currentPage * this.state.itemsCountPerPage,
                    (currentPage + 1) * this.state.itemsCountPerPage
                )

            this.setState({
                pagesCount: Math.ceil(filteredItems.length / this.state.itemsCountPerPage)
            });
            this.setState({ responseData: filterItem })

        }
        console.log('filtered item ', this.state.responseData);
        console.log(this.state.filteringItems)
    }


    // onSelectCheckBoxProject = (event, id) => {
    //     console.log(event.target, id);
    //     console.log(event.target.checked);
    //     if(event.target.checked) {
    //         checked = event.target.checked;
    //         fileName.push(id);
    //         console.log(fileName)
    //     } else {
    //         checked = event.target.checked;
    //         // fileName = [];
    //         console.log(fileName)
    //     }
        
    // }


    // onSelectAllCheckbox = (selectEvent) => {
    //     // arrCheck = [];
    //     // fileName = [];
    //     // responseText = '';
    //     console.log('check event value : ', selectEvent.target.value);
    //     console.log('checked ', selectEvent.target.checked)
    //     var checkbox;
    //     console.log('select event : ', selectEvent.target);
    //     var str = selectEvent.target.value.split(',');
    //     console.log(str)
    //     arrCheck.push(str[0]);
    //     fileName.push(str[1])
    //     // var checkboxes = document.getElementById(selectEvent.target.value[1]);
    //     // for (checkbox of checkboxes) {
    //     //     checkbox.checked = true;
    //     //     console.log('check : ', checkbox.checked);
    //     //     var str = checkbox.value.split(',');
    //     //     console.log(str)
    //     //     arrCheck.push(str[0]);
    //     //     fileName.push(str[1])
    //     // }
    //     if (selectEvent.target.checked) {
    //         console.log(arrCheck, fileName);
    //     }
    // }

    // onDownloadSelected = (downloadEvent) => {
    //     console.log(downloadEvent);
    //     console.log(checked)
    //     if(checked) {
    //         var fold;
    //         console.log(JSZip.version)
    //         var zip = new JSZip();
    //         console.log(JSZip);
    //         fileName.forEach((name) => {
    //             console.log('name : ', name);
    //             arrData.map((project, ind) => {
    //                 arrCheck = [];
    //                 fileNameList = [];
    //                 // console.log(project.files)
    //                 if (project.XTMProjectName === name) {
    //                     console.log(project.files);
    //                     zip = new JSZip();
    
    //                     project.files.map((file, ind) => {
    //                         fileNameList.push(file.fileName);
    //                         arrCheck.push(file.destinationUrl);
    //                         return (fileNameList, arrCheck);
    //                     });
    
    //                     fileNameList.forEach((name) => {
    //                         fold = zip.folder(name);
    //                         arrCheck.forEach((url) => {
    //                             const blobPromise = fetch(url).then(r => {
    //                                 if (r.status === 200) return r.blob()
    //                                 return Promise.reject(new Error(r.statusText))
    //                             })
    //                             if (url.includes(name)) {
    //                                 const nameOfFile = url.substring(url.lastIndexOf('/'));
    //                                 var stringSplit = nameOfFile.lastIndexOf('-');
    //                                 console.log('name of file : ', nameOfFile, nameOfFile.substring(stringSplit + 1))
    //                                 fold.file(nameOfFile, blobPromise)
    //                             }
    
    //                         })
    
    //                     })
    //                 }
    //                 return name;
    
    //             });
    
    //             zip.generateAsync({ type: 'blob' }).then((content) => {
    //                 console.log('content ', content);
    
    //                 saveAs(content, (name + '.zip'))
    //             }).catch((e) => console.log(e))
    //             // fold = zip.folder(name);
    //             // arrCheck.forEach((url) => {
    //             //     const blobPromise = fetch(url).then(r => {
    //             //         if (r.status === 200) return r.blob()
    //             //         return Promise.reject(new Error(r.statusText))
    //             //     })
    //             //     if (url.includes(name)) {
    //             //         const nameOfFile = url.substring(url.lastIndexOf('/'))
    //             //         fold.file(nameOfFile, blobPromise)
    //             //     }
    
    //             // })
    
    //         })
    //     } else {
    //         alert('Select any zip folder to download selected project files')
    //     }
        

    //     // zip.generateAsync({ type: 'blob' }).then((content) => {
    //     //     console.log('content ', content);

    //     //     saveAs(content, "check.zip")
    //     // }).catch((e) => console.log(e))

    // }

    downloadZipFolder = (zipEvent, id) => {
        arrCheck = [];
        fileName = [];
        console.log('zip event ', zipEvent.target);
        console.log('zip event id ', id);
        var zip;
        var fold;
        var fname; var fileName; var fileArray = [];var fileCnt = 0;

        arrData.map((project, ind) => {
            // console.log(project.files)
            if (project.XTMProjectName === id) {
                console.log(project.files);
                zip = new JSZip();

                project.files.map((file, ind) => {
                    fileName.push(file.fileName);
                    arrCheck.push(file.destinationUrl);
                    return (fileName, arrCheck);
                });

                fileName.forEach((name,index) => {

                    fileCnt =  fileName.length;
                    if(fileCnt == 1){
                        fileArray = name.split('.');
                        if(fileArray[1] === 'zip' || fileArray[1] === 'ZIP'){
                            saveAs(arrCheck[index])
                        } else {
                            fold = zip.folder(name);
                            arrCheck.forEach((url) => {
                                const blobPromise = fetch(url).then(r => {
                                    if (r.status === 200) return r.blob()
                                    return Promise.reject(new Error(r.statusText))
                                })
                                if (url.includes(name)) {
                                    const nameOfFile = url.substring(url.lastIndexOf('/'));
                                    // var stringSplit = nameOfFile.lastIndexOf('-');
                                    console.log('name of file : ', nameOfFile)
                                    fold.file(nameOfFile, blobPromise)
                                }
                            })                            
                        }                     
                    } else {
                        fold = zip.folder(name);
                        arrCheck.forEach((url) => {
                            const blobPromise = fetch(url).then(r => {
                                if (r.status === 200) return r.blob()
                                return Promise.reject(new Error(r.statusText))
                            })
                            if (url.includes(name)) {
                                const nameOfFile = url.substring(url.lastIndexOf('/'));
                                // var stringSplit = nameOfFile.lastIndexOf('-');
                                console.log('name of file : ', nameOfFile)
                                fold.file(nameOfFile, blobPromise)
                            }
                        })
                    }
                })
            }
            return id;

        });

        console.log(fileArray.length);
        if(fileArray.length == 0){
            zip.generateAsync({ type: 'blob' }).then((content) => {
                console.log('content ', content);
                saveAs(content, (id + '.zip'))
            }).catch((e) => console.log(e))            
        }
    }

    loadData = () => {
        SubmittedApiLayerClient.getSubmitData()
        .then(result => {
            console.timeEnd('Timer: ')
            this.setState({ isLoading: false })
            console.log(result)
            arrData = [];
            for (var data of result.data.data) {
                console.log(data)
                arrData.push(data)
                for (var files of data.files) {
                    console.log(files.fileName);
                    console.log(files.destinationUrl)
                    console.log(files.toLanguage)
                }
            }
            // this.setState({
            //     pagesCount: Math.ceil(arrData.length / this.state.itemsCountPerPage)
            // })
            // const ch = arrData
            //     .slice(
            //         currentPage * this.state.itemsCountPerPage,
            //         (currentPage + 1) * this.state.itemsCountPerPage
            //     )
            this.setState({ responseData: arrData })
        })
       
    } 

    deleteRecord = (event, projId) => {
        var delflag = window.confirm("Are you sure to delete this record !!");
        if(delflag){
            SubmittedApiLayerClient.deleteRecord(projId)
            .then(result => {
                alert("Record successfully deleted ..!");
                this.loadData();
            });
        }
    }

  getSortDirection = (columname) => {
      if(columname == 'project' )
      {
        if (this.state.order === 'asc') {
        return '↑';
       }
        else
        return '↓';
}
    
}
    render() {

        return (
            <Card>
                <Card.Body>
                    <>

                    <table id="example" class="table table-hover table-bordered" bodyStyle={ {width: 50, maxWidth: 50, wordBreak: 'break-all' }} >
                        <thead>
                            <tr>
                                <th>Submitted Project</th>
                                <th>Project Name</th>
                                <th>File Info</th>
                                <th>ZIP folder</th>
                                <th>Submitted Date</th>
                                <th>Due Date</th>
                                <th>Status</th>
                                <th>Source Lang.</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.responseData.map((result) => {
                                return (
                                
                                    <tr>
                                    <td><p>{result.XTMProjectName}</p></td>
                                    <td><p>{result.projectName}</p></td>
                                    {/* <td>{result.dueDate}</td> */}
                                    <td> 
                                        <thead className="tableHead">
                                            <tr>
                                                <th className="thHead">File Name</th>
                                                <th className="thHead">Download Link</th>
                                                <th className="thHead">Target Language</th>
                                            </tr>
                                        </thead>

                                        {(result.files.length === 0 && result.status === 'Send for Translation') && (
                                            <tr>
                                                <td className="tdRow"><p>{'Files sent for the Translation'}</p></td>
                                                <td className="tdRow"><p>{'Files sent for the Translation'}</p></td>
                                                <td className="tdRow"><p>{'Files sent for the Translation'}</p></td>
                                            </tr>                                                        
                                        )}

                                        {(result.files.length === 0 && result.status === 'Error') && (
                                            <tr>
                                                <td className="tdRow"><p>{'Files sent for the Translation'}</p></td>
                                                <td><p id="link"><FontAwesomeIcon id={result.XTMProjectName} className="disabled" icon={faFileArchive} size="3x" /></p></td>
                                                <td className="tdRow"><p>{'Files sent for the Translation'}</p></td>
                                            </tr>                                                                                                                
                                        )}
                                                          
                                        {result.files.map((data, ind) => (
                                            <tr>
                                                <td className="tdRow">
                                                    <p>
                                                        {data.fileName}
                                                    </p>
                                                </td>
                                                <td className="tdRow"><p id="link"> <a href={data.destinationUrl} target="_blank" rel="noopener noreferrer" onClick={this.checkDownload}><FontAwesomeIcon icon={faDownload} size="lg" /></a></p></td>
                                                <td className="tdRow text-center">
                                                    {(data.toLanguage === 'zip_ZIP' ? <p>{result.zipLang}</p> : <p>{data.toLanguage}</p>)}
                                                    {/* <p>{data.toLanguage}</p> */}
                                                </td>
                                            </tr>
                                        ))}
                                        </td>
                                        {(result.status === 'Translated') ? (
                                            <td>
                                                <p id="link" >
                                                    <FontAwesomeIcon id={result.XTMProjectName} onClick={(e) => this.downloadZipFolder(e, result.XTMProjectName)} icon={faFileArchive} size="3x" />
                                                </p>
                                            </td>
                                        ): (
                                            <td>
                                              <p>NA</p>  
                                            </td>                                            
                                        )}
                                                    
                                    <td>{result.dateSubmitted}</td>
                                    <td>{result.dueDate}</td>
                                    {(result.status !== 'Error') && (
                                                        <td>
                                                            {result.status}
                                                        </td>
                                                    )}
                                                    {(result.status === 'Error') && (
                                                        <td style={{ color: 'red' }}>
                                                            {result.status}
                                                            <br />
                                                            <p id="required-field2">{'* ' + result.errorMsg}</p>
                                                        </td>
                                                    )}
                                    <td>{result.fromLanguage}</td>   
                                    <td  style={{ textAlign: 'center', color:'red' }} > 
                                                        <FontAwesomeIcon icon={faTrash} size="sm" style={{cursor:'pointer'}} onClick={(e) => this.deleteRecord(e, result.XTMProjectName)} alt="Delete Project"/> 
                                  </td>
                                  
                                   
                                                                                                                    
                                    </tr>
                                
                                )
                            })}
                        </tbody>
                    </table>

                    </>

</Card.Body>

</Card>
)
}

}

export default SubmitProject;
