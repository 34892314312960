import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
// import { Auth0Provider } from "@auth0/auth0-react";
// import config from "./auth_config.json";
// import history from "./utils/history";

// const onRedirectCallback = (appState) => {
//   console.log(appState)
//   history.push(
//     appState && appState.returnTo
//       ? appState.returnTo
//       : window.location.pathname
//   );
// };

ReactDOM.render(
  // <Auth0Provider
  //   domain="dev-1e7jx8dm.eu.auth0.com"
  //   clientId="0rnv86LWA7PnUSiJIhKhHwAnnGRxh3NZ"
  //   // clientSecret={config.clientSecret}
  //   audience='https://dev-1e7jx8dm.eu.auth0.com/api/v2/'
  //   redirectUri="http://localhost:3000/dashboard"
  //   responseType='token'
  //   scope= 'openid profile email'
  //   onRedirectCallback={onRedirectCallback}
  // >
    <App />,
  // </Auth0Provider>,
  document.getElementById("root")
);

