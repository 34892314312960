import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import RenderPage from './RenderPage';
import logo from './Ikea.png';
import './Dashboard.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faComments, faLanguage, faTasks } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';
import { auth0Client } from '../auth';
import {
  Row , Col , NavbarBrand, DropdownItem, Input, 
  Collapse,
  // Navbar,
  NavbarToggler,
  // Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
// import jQuery from 'jquery';
import { Navbar, Nav, NavLink, NavItem } from 'react-bootstrap';
import PropTypes from "prop-types";


window.ATL_JQ_PAGE_PROPS = {
  "triggerFunction": function (showCollectorDialog) {
    window.showCollectorDialog = showCollectorDialog;
    // jQuery("#myCustomTrigger").on('click', function (e) {
    //   e.preventDefault();
    //   showCollectorDialog();
    // });
  }
};

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tab: null,
      isOpen: false,
      getProfile: null,
      isAuthenticated: false,
      tokenRenewed: false,
      getIdToken: null,
      modalShow: false,
      key: 'home',
      layoutMode: 'basic',
      value: "",
    }



    console.log('auth0 client ', auth0Client);
    console.log('isAuthenticated ', this.state.isAuthenticated);

    if (sessionStorage.getItem('activeTab') === null || sessionStorage.getItem('key') === null) {
      sessionStorage.setItem('activeTab', 'Request Translation');
      sessionStorage.setItem('key', 'home')
    }

  }

  componentDidMount() {
    this.checkAuth();
  }

  async checkAuth() {
    await auth0Client.handleAuthentication();

    window.history.replaceState({}, document.title, window.location.href.split("#")[0]);
    console.log(auth0Client.getProfile(), auth0Client.getIdToken(), auth0Client.isAuthenticated())
    this.setState({
      getProfile: localStorage.getItem('profile'),
      isAuthenticated: localStorage.getItem('isAuthenticated'),
      getIdToken: localStorage.getItem('idToken')
    });
    console.log(this.state.getProfile, this.state.isAuthenticated);

  }

  toggle = () => {
    // this.setState({ isOpen: !this.state.isOpen });
    this.setState({ isOpen: JSON.parse(localStorage.getItem('isOpen')) });
    JSON.parse(localStorage.getItem('isOpen'));
  };
  logoutWithRedirect = () => {
    auth0Client.signOut();
    localStorage.clear();
    sessionStorage.clear()
    // history.replace('/')
    console.log('logged out successfully')
  }


  onSubmitTranslation = (event) => {
    console.log(event);
    this.setState({ tab: '1' });
    if ((sessionStorage.getItem('activeTab') === null) || (sessionStorage.getItem('activeTab') === 'Submit Project') || (sessionStorage.getItem('activeTab') === 'Request Translation')) {
      sessionStorage.removeItem('activeTab');
      sessionStorage.removeItem('key')
      sessionStorage.setItem('activeTab', 'Request Translation');
      sessionStorage.setItem('key', 'home')
    }

  }

  onSubmitProject = (evt) => {
    console.log(evt);
    this.setState({ tab: '2' })
    if ((sessionStorage.getItem('activeTab') === 'Request Translation') || (sessionStorage.getItem('activeTab') === null)) {
      sessionStorage.removeItem('activeTab');
      sessionStorage.removeItem('key')
      sessionStorage.setItem('activeTab', 'Submit Project');
      sessionStorage.setItem('key', 'submitted_project')
      console.log('activeaTab', sessionStorage.getItem('activeTab'))

    }

  }

  keySelection = (key) => {
    console.log(key)
    this.setState({ key: key });
  }

  onSubmitFeedback = (evt) => {
    console.log(evt);
    window.showCollectorDialog()
  }

  changeLayout = (e) => {
    console.log(e.target.value);
    this.setState({layoutMode: e.target.value });
    console.log(this.state.layoutMode);
  }

  render() {
    window.addEventListener('focus', function storageEvent() {
      if (localStorage.length === 0) {
        auth0Client.signOut();
        sessionStorage.clear()
      }
    })

    return (
      <React.Fragment>
        {(this.state.isAuthenticated || localStorage.getItem('isAuthenticated')) && (
          <div className="nav-container">
            {/* <Navbar style={{ backgroundColor: 'white' }} color="light" light expand="md">
              <Container fluid className="navbarContainer">
                <div className="content">
                  <img
                    alt=""
                    style={{ height: '40px', width: '80px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    src={logo}
                    className="img-fluid rounded"
                  />
                </div>
                <div className="text">
                  <b>File Translation User Portal</b>
                  <p>Secure Translations adapted to IKEA tone of voice</p>
                </div>

                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={(this.state.isOpen)} navbar show>
                  <Nav className="mr-auto" navbar>

                  </Nav>
                  <Nav className="d-none d-md-block m-auto" navbar>

                    {(this.state.isAuthenticated) && (
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret id="profileDropDown">
                          <FontAwesomeIcon icon="user" size="lg" />

                        </DropdownToggle>
                        <DropdownMenu id="dropdownMenu" right>
                          <img
                            src={(localStorage.getItem('picture'))}
                            alt="Profile"
                            className="nav-user-profile rounded-circle"
                            width="50"
                          /> <br />
                          <span>{(localStorage.getItem('name'))}</span> <br />
                          <span>{(localStorage.getItem('email'))}</span>
                          <br /> <br />
                          <Button id="button" size="sm" onClick={() => this.logoutWithRedirect()}>Logout</Button>

                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </Nav>
                </Collapse>
                {((this.state.isAuthenticated === false) && localStorage.getItem('isAuthenticated')) && (
                  <Collapse isOpen={JSON.parse(localStorage.getItem('isOpen'))} navbar>
                    <Nav className="mr-auto" navbar>

                    </Nav>
                    <Nav className="d-none d-md-block" navbar>

                      {(localStorage.getItem('isAuthenticated')) && (
                        <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle nav caret id="profileDropDown">
                            <FontAwesomeIcon icon="user" size="lg" />

                          </DropdownToggle>
                          <DropdownMenu id="dropdownMenu" right>
                            <img
                              src={(localStorage.getItem('picture'))}
                              alt="Profile"
                              className="nav-user-profile rounded-circle"
                              width="50"
                            /> <br />
                            <span>{(localStorage.getItem('name'))}</span> <br />
                            <span>{(localStorage.getItem('email'))}</span>
                            <br /> <br />
                            <Button id="button" size="sm" onClick={() => this.logoutWithRedirect()}>Logout</Button>

                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                    </Nav>
                  </Collapse>
                )}

              </Container>

            </Navbar> */}

{/* New Componet */}

<Navbar style={{ backgroundColor: 'white' }} color="light" light expand="md" className="px-5">
          <NavbarBrand>
            <Row>
              <Col className="pr-0">
                <div className="content">
                  <img
                    alt=""
                    style={{ height: '50px', width: '110px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    src={logo}
                    className="img-fluid"
                  />
                </div>              
              </Col>
              <Col className="pl-0">
                <div className="text">
                  <b>File Translation User Portal</b>
                  <p>Secure Translations adapted to IKEA tone of voice</p>
                </div>                 
              </Col>
            </Row>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {(this.state.isAuthenticated) && (
                <>
                  <NavItem>
                    <Input type="select" name="select" id="exampleSelect" onChange={this.changeLayout.bind(this)} value={this.state.layoutMode}>
                      <option value="basic">Basic</option>
                      <option value="advanced">Advanced</option> 
                    </Input>                
                  </NavItem>
                  <NavItem>
                    <UncontrolledDropdown nav inNavbar className="p-2">
                      <DropdownToggle nav caret>
                        <FontAwesomeIcon icon="user" size="lg" className="mx-2"/>
                      </DropdownToggle>
                      <DropdownMenu right className="text-center text-wrap">
                        <DropdownItem>
                          <img
                            src={(localStorage.getItem('picture'))}
                            alt="Profile"
                            className="nav-user-profile rounded-circle"
                            width="50"
                          />
                          <p className="text-wrap">{(localStorage.getItem('name'))}</p>
                          <p>{(localStorage.getItem('email'))}</p>
                        </DropdownItem>
                        <DropdownItem>
                          <Button  size="sm" onClick={() => this.logoutWithRedirect()}>LOGOUT</Button>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </NavItem>
                </>
              )}
            </Nav>
          </Collapse>
          {((this.state.isAuthenticated === false) && localStorage.getItem('isAuthenticated')) && ( 

            <Collapse isOpen={JSON.parse(localStorage.getItem('isOpen'))} navbar>
            <Nav className="d-none d-md-block ml-auto" navbar>

              {(localStorage.getItem('isAuthenticated')) && (

                <>                
                  <Nav className="mr-auto" navbar>
                    <NavItem>
                      <Input type="select" name="select" id="exampleSelect" onChange={this.changeLayout.bind(this)} value={this.state.layoutMode}>
                        <option value="basic">Basic</option>
                        <option value="advanced">Advanced</option>
                      </Input>                
                    </NavItem>  
                    <NavItem className="p-2">
                      <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret id="profileDropDown">
                        <FontAwesomeIcon icon="user" size="lg" />

                      </DropdownToggle>
                      <DropdownMenu id="dropdownMenu" right>
                        <img
                          src={(localStorage.getItem('picture'))}
                          alt="Profile"
                          className="nav-user-profile rounded-circle"
                          width="50"
                        /> <br />
                        <span>{(localStorage.getItem('name'))}</span> <br />
                        <span>{(localStorage.getItem('email'))}</span>
                        <br /> <br />
                        <Button id="button" size="sm" onClick={() => this.logoutWithRedirect()}>Logout..!</Button>

                      </DropdownMenu>
                    </UncontrolledDropdown>                      
                    </NavItem>                  
                  </Nav>                
                </>
              )}
            </Nav>
            </Collapse>
          )}
        </Navbar>

{/* End component */}

            <Navbar style={{ backgroundColor: 'white', boxShadow: '2px 2px 2px gainsboro' }}>
              <React.Fragment>
                <Nav className="mr-auto navItem justify-content-center" activeKey={sessionStorage.getItem('key')} style={{ flex: 1 }} onSelect={this.keySelection} navbar>
                  <NavItem>
                    <NavLink eventKey="home" onClick={(e) => this.onSubmitTranslation(e)}><b>Request Translation</b></NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey="submitted_project" onClick={(e) => this.onSubmitProject(e)}><b>Submitted Project</b></NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink eventKey="feedback" onClick={this.onSubmitFeedback}><b>Feedback</b></NavLink>
                  </NavItem>
                </Nav>

              </React.Fragment>
            </Navbar>

            {/* <br /> <br /> */}
            <div className="render">
              {/* <RenderPage tab={this.state.tab} /> */}
              <RenderPage tab={this.state.tab} dataParentToChild = {this.state.layoutMode}/>
            </div>
            <br />
          </div>
        )}
      </React.Fragment>

    )


  }
}

export default Dashboard;
