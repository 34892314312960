import auth0 from 'auth0-js';
import { Component } from 'react';

class Auth extends Component {
    constructor() {
        super()
        this.auth0 = new auth0.WebAuth({
            // the following three lines MUST be updated
            domain: 'ingka-icow-prod.eu.auth0.com',   // Production
            audience: 'https://ingka-icow-prod.eu.auth0.com/userinfo',   // Production
            clientID: 'vtOrUR3rRKztsnuxelCneOCWQ9aar3vm',   // Production
            // redirectUri: 'https://ingka-gls-portal-prod.ew.r.appspot.com/dashboard',   // Production

            // domain: 'ingka-icow-stag.eu.auth0.com', // Testing
            // audience: 'https://ingka-icow-stag.eu.auth0.com/userinfo', // Testing
            // clientID: 'GM9CSiW59uZz58XQepcy4NFdULbDFAjB', // Testing
            // redirectUri: 'http://localhost:3000/dashboard', // Testing

            redirectUri: 'https://translatefile.ikea.net/dashboard', 
            
            responseType: 'id_token',
            scope: 'openid email profile'
        });

        this.getProfile = this.getProfile.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.signIn = this.signIn.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    getProfile() {
        localStorage.setItem('name', this.profile.name);
        localStorage.setItem('email', this.profile.email);
        localStorage.setItem('picture', this.profile.picture)
        return this.profile;
    }

    getIdToken() {
        return this.idToken;
    }

    isAuthenticated() {
        const expiry = new Date().getTime() < this.expiresAt;
        return expiry;
    }

    signIn() {
        this.auth0.authorize();
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash({
                hash: window.location.hash,
                responseType: 'id_token'
            }, (err, authResult) => {
                if (err) return reject(err);
                if (!authResult || !authResult.idToken) {
                    return reject(err);
                }
                this.idToken = authResult.idToken;
                this.profile = authResult.idTokenPayload;
                // set the time that the id token will expire at
                this.expiresAt = authResult.idTokenPayload.exp * 1000;
                localStorage.setItem('idToken', this.idToken);
                localStorage.profile = JSON.stringify(this.profile);
                localStorage.setItem('isOpen', true);
                localStorage.setItem('isAuthenticated', ((new Date().getTime() < this.expiresAt) ? true : false))
                resolve();
            });
        })
    }


    signOut() {
        // clear id token, profile, and expiration
        this.auth0.logout({
            // returnTo: 'https://ingka-gls-portal-prod.ew.r.appspot.com/dashboard', // Production
            returnTo: 'https://translatefile.ikea.net',
            // returnTo: 'http://localhost:3000/', // Testing
            clientID: 'vtOrUR3rRKztsnuxelCneOCWQ9aar3vm', // Production
            // clientID: 'GM9CSiW59uZz58XQepcy4NFdULbDFAjB', // Testing
        });
        // localStorage.clear();
    }
}

export const auth0Client = new Auth();
export default Auth;