import { Component } from "react";
import axios from 'axios';
// import { json } from "express";

class XTMApiLayer extends Component {

  async GetCall(url) {
    // return await fetch(url)
    //   .then(async response => await response.json())
    //   .then(json => {
    //     console.log(json);
    //     return json;
    //   })
    console.time('Timer2: ')
    return await axios({
      method: 'GET',
      url: url,
      headers: {
        'apikey': 'e062bb18-d908-48f2-b30a-bd556a06883e' //for production use
        // 'apikey': 'e062bb18-d908-48f2-b30a-bd556a06883e87h' // for local testing use
      }
    })
      .then(json => {
        console.log(json);
        console.timeEnd('Timer2: ');
        console.timeLog('Timer2: ');
        return json;
      })


  }
  async PostCall(url, opts) {

    return axios({
      method: 'POST',
      url: url,
      data: opts,
      headers: {
        'Content-Type': 'multipart/form-data;boundary="MyBoundary"',
        'apikey': 'e062bb18-d908-48f2-b30a-bd556a06883e' //for production use
        // 'apikey': 'e062bb18-d908-48f2-b30a-bd556a06883e87h' // for local testing use
        
      }
    })

    // return fetch(url,
    //   {
    //     method: 'post',
    //     body: opts,
    //   }
    // )
    //   .then(response => response)
    //   .then(json => {
    //     console.log(json);
    //     return json;
    //   })

  }


  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }

}
export const XTMApiLayerClient = new XTMApiLayer();
export default XTMApiLayer;